<style>

</style>
<script>

export default {
  components: {

  },
  methods: {

  },
  mounted() {

  },
  data() {
    return {

    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col justify-content-center">
      <div  class="info-component text-center align-middle mt-auto mb-auto">
        <div class="info-component-space">
          <i class="fad fa-mobile-alt text-danger info-component-icon"></i>
        </div>
        <div class="row" style="margin-top: 20px;">
          <div class="col-lg-12">
            <h3 v-b-tooltip.hover title="soon™ 👷">{{$t('error.mobile.unavailable.title')}}</h3>
            <h5 class="text-muted">{{$t('error.mobile.unavailable.message')}}</h5>
            <h6>
              <marquee>soon™</marquee>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
